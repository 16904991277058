/** @format */

import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Modal, Form, InputGroup, Button, CloseButton } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BsFileEarmarkText, BsFileEarmarkImage } from "react-icons/bs";
import { toast } from "react-toastify";
import "./addArticle.css";
import { useQuery } from "react-query";
import { getAuthor } from "../Search-page/search.queryies";
import { Select } from "antd";

const AddArticle = ({ authorId, show, setShow }) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedCoAuthors, setSelectedCoAuthors] = useState([]);
  const [fileIcon, setFileIcon] = useState(null);
  const [keywords, setKeywords] = useState([]);

  const token = localStorage.getItem("accessToken");

  const { data: author } = useQuery({
    queryKey: ["authorList"],
    queryFn: () => getAuthor(),
  });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL2}/category`,
        );
        setCategories(response.data);
      } catch (error) {}
    };

    fetchCategories();
  }, []);

  const onCategoryChange = useCallback(async (categoryId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL2}/subcategory/sub/${categoryId}`,
      );
      setSubCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileTypes = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      setFileIcon(
        fileTypes.test(file.name) ? (
          <BsFileEarmarkImage />
        ) : (
          <BsFileEarmarkText />
        ),
      );
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post(`${process.env.REACT_APP_API_URL2}/file/uploadFile`, formData, {
          headers: { Authorization: token },
        })
        .then((response) => {
          setValue("source_id", response.data.link.id);
        })
        .catch((error) => {});
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === " " || event.key === "Enter") {
      if (event.target.value.trim() !== "") {
        setKeywords([...keywords, event.target.value.trim()]);
        setValue("keywordInput", "");
      }
      event.preventDefault();
    }
  };

  const removeKeyword = (index) => {
    setKeywords(keywords.filter((_, i) => i !== index));
  };

  const handleAddKeywords = (inputValue) => {
    const newKeywords = inputValue
      .split(",")
      .map((kw) => kw.trim())
      .filter((kw) => kw);
    if (newKeywords.length) {
      setKeywords([...keywords, ...newKeywords]);
      setValue("keywordInput", "");
    }
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.includes(",")) {
      handleAddKeywords(inputValue);
    }
  };

  const onSubmit = async (data) => {
    const formattedKeywords = keywords.join(",");
    console.log(formattedKeywords);
    const articleData = {
      title: data.title,
      abstract: data.abstract,
      description: data.description,
      keyword: formattedKeywords,
      author_id: authorId,
      categoryId: Number(data.categoryId),
      SubCategoryId: Number(data.SubCategoryId),
      source_id: data.source_id,
      coAuthorIds: selectedCoAuthors,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL2}/article/user/create`,
        articleData,
        { headers: { Authorization: token } },
      );

      console.log(response);
      if (response.status === 200) {
        toast.success("Maqola muvaffaqiyatli yaratildi!");
        handleClose();
        reset();
      }
    } catch (error) {
      console.log(error);
      setMessage("Xatolik yuz berdi, qayta urinib ko‘ring!");
    }
  };

  function handleOpen() {
    setShow(true);
  }
  function handleClose() {
    setShow(false);
  }

  return (
    <>
      <Button className="openModal" onClick={handleOpen}>
        {window.innerWidth < 500 ? "+" : "Yangi maqola yaratish +"}
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>Maqola yaratish</Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-2">
              <Form.Label>Maqola sarlavhasi</Form.Label>
              <Form.Control {...register("title", { required: true })} />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Abstrakt</Form.Label>
              <Form.Control
                {...register("abstract", { required: true })}
                as="textarea"
                maxLength={1000}
                style={{ minHeight: "100px", maxHeight: "150px" }}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Tavsif</Form.Label>
              <Form.Control
                {...register("description", { required: true })}
                as="textarea"
                maxLength={1000}
                style={{ minHeight: "100px", maxHeight: "150px" }}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Kalit so‘zlar</Form.Label>
              {keywords.length > 0 && (
                <div className="keywords">
                  {keywords.map((keyword, index) => (
                    <div key={index} className="keyword-chip">
                      {keyword}
                      <CloseButton onClick={() => removeKeyword(index)} />
                    </div>
                  ))}
                </div>
              )}

              <Form.Control
                {...register("keywordInput")}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                placeholder="+ Yangi kalit so‘z"
              />
            </Form.Group>
            <div className="form-row">
              <Form.Group className="mb-2 form-col">
                <Form.Label>Yo‘nalishlar</Form.Label>
                <Form.Control
                  {...register("categoryId", { required: true })}
                  as="select"
                  onChange={(e) => onCategoryChange(e.target.value)}
                >
                  <option>Yo‘nalish tanlang</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-2 form-col">
                <Form.Label>Yo‘nalish sohasi</Form.Label>
                <Form.Control
                  {...register("SubCategoryId", { required: true })}
                  as="select"
                >
                  <option>Yo‘nalish sohasini tanlang</option>
                  {subCategories.map((subCategory) => (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-2 w-full">
                <Form.Label>Hammualliflar</Form.Label>
                <Select
                  mode="multiple"
                  placeholder="Hammualliflarni tanlang"
                  style={{ width: "100%" }}
                  value={selectedCoAuthors}
                  onChange={setSelectedCoAuthors}
                  options={author?.map((a) => ({
                    label: a.full_name,
                    value: a.id,
                  }))}
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().includes(input.toLowerCase())
                  }
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Group>
            </div>

            <Form.Group className="mb-2">
              <Form.Label>Maqola yuklash</Form.Label>
              <InputGroup>
                <InputGroup.Text>{fileIcon}</InputGroup.Text>
                <Form.Control type="file" onChange={handleFileUpload} />
                <Form.Control
                  {...register("source_id", { required: true })}
                  type="hidden"
                />
              </InputGroup>
            </Form.Group>
            <Button className="article-submit w-full" type="submit">
              Maqola yaratish
            </Button>
            {errors.title && toast.error("Sarlavha talab etiladi")}
            {message && toast.error(message)}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddArticle;
