import React from "react";
import { Input } from "antd";

const OtpInput = () => {
  const onChange = (text) => {
    console.log("onChange:", text);
  };
  const sharedProps = {
    onChange,
  };

  return (
    <div className="container">
      <div className="flex items-center justify-center w-full h-full">
        <Input.OTP mask="🔒" {...sharedProps} />
      </div>
    </div>
  );
};

export default OtpInput;
